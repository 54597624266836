<template>
  <div :class="['app-input', ...dynamicWrapClasses]">
    <span :class="['app-input__label', { 'active': isActive }]">{{ label }}</span>
    <input
        :value="inputValue"
        @input="handleChange"
        :type="type"
        :inputmode="inputMode"
        :min="min"
        :max="max"
        :disabled="isDisabled"
        :required="isRequired"
        @focus="handleFocus"
        :[dataAttr]="true"
        @blur="onBlur"
        @keydown.enter="enterPressed"
        @keydown="handleKeyDown"
    />
    <slot></slot>
    <p v-if="isError" class="app-input__error">
      {{ textError }}
    </p>
  </div>
</template>

<script>
import { validateInput } from "@/validators/inputValidator"

export default {
  name: 'appInput',
  emits: [
    'input',
    'enter-pressed',
    'validation-change',
    'keydown',
  ],
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [ String, Number ],
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isPhoneFormat: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dataAttr: {
      type: String,
      default: '',
    },
    isUnderlineStyle: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: 'text',
    },
    showValidationError: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isActive: false,
      validationErrors: {},
      textError: '',
      inputValue: this.value || '',
      isInvalid: false,
    }
  },
  computed: {
    dynamicWrapClasses() {
      return [
        {'error': this.isError},
        {'disabled': this.isDisabled},
        {'app-input_underline': this.isUnderlineStyle}
      ]
    },
    isError() {
       return Object.keys(this.validationErrors).length > 0
    },
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue
    },
    showValidationError(newValue) {
      this.isInvalid = !!newValue
      this.validateInputOnClick()
    },
  },
  methods: {
    onBlur() {
      if (this.inputValue === '' || this.inputValue === null || this.inputValue === undefined) {
        this.isActive = false
      }
    },
    checkIsBlurred() {
      if (
          (typeof this.inputValue === 'string' && this.inputValue.length > 0) ||
          (typeof this.inputValue === 'number' && this.inputValue >= 0)
      ) this.isActive = true
    },
    phoneNumberMask(rawValue) {
      return (
        rawValue
          .replace(/\D/g, '')
          .replace(
            /(\+7|7|8)-?\s*\(?(\d{1,3})?\)?-?\s*(\d{1,3})?-?\s*(\d{1,2})?-?\s*(\d{1,2})?.*/,
            '+7 ($2) $3-$4-$5',
          )
          .replace(/[-() ]+\s*$/gu, '')
          .replace(/^\+*[0-9]/, '+7') || ''
      )
    },
    handleFocus() {
      this.isActive = true
      this.handleChange({ target: { value: this.inputValue } })
    },
    handleChange(e) {
      let filteredValue = e.target.value
      let maskedValue = this.isPhoneFormat ? this.phoneNumberMask(filteredValue) : filteredValue

      this.validationErrors = this.validateInputValue(maskedValue)
      this.updateErrorText()
      this.inputValue = maskedValue
      this.$emit('validation-change', !this.isError)
      this.$emit('input', maskedValue)
    },
    enterPressed() {
      this.$emit('enter-pressed')
    },
    handleKeyDown(event) {
      this.$emit('keydown', event)
    },
    updateErrorText() {
      this.textError = this.isError ? Object.values(this.validationErrors)[0] : ''
    },
    validateInputValue(value) {
      return validateInput(value, {
        isRequired: this.isRequired,
        minLength: this.minLength,
        maxLength: this.maxLength,
        isPhoneFormat: this.isPhoneFormat
      })
    },
    validateInputOnClick() {
      if (this.isInvalid) {
        this.validationErrors = this.validateInputValue(this.inputValue)
      } else {
        this.validationErrors = {}
      }
      return this.updateErrorText()
    },
  },
  updated() {
    this.checkIsBlurred()
  },
  mounted() {
    this.checkIsBlurred()
    if (this.inputValue) {
      this.validationErrors = this.validateInputValue(this.inputValue)
      this.updateErrorText()
      this.$emit('validation-change', !this.isError)
    }
  }
}
</script>
