var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c("app-input", {
        attrs: {
          label: "Указать адрес",
          "is-required": _vm.isRequired,
          "show-validation-error": _vm.inputValidation
        },
        on: {
          "validation-change": function($event) {
            return _vm.updateOrderFormValidation($event)
          },
          input: _vm.handleInput,
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleKeyDown.apply(null, arguments)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleKeyUp.apply(null, arguments)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleEnter.apply(null, arguments)
            }
          ]
        },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      }),
      _vm._v(" "),
      _vm.isVisibleList
        ? _c(
            "ul",
            { staticClass: "autocomplete__prompt-list" },
            _vm._l(_vm.searchedAddresses, function(address, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "autocomplete__prompt-item",
                  class: { active: index === _vm.selectedIndex },
                  on: {
                    click: function($event) {
                      return _vm.handleLoadedAddressClick(
                        index,
                        _vm.isOnCreateOrderPage
                      )
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(address) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "autocomplete__prompt-message" }, [
        _vm._v(_vm._s(_vm.message))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-24fd54cc", { render: render, staticRenderFns: staticRenderFns })
  }
}